export default [
  {
    path: '/systemManagement',
    name: 'systemManagement',
    component: () => import(/* webpackChunkName: "systemManagement" */ './index'),
    meta: {
      type: 'systemManagement'
    },
    redirect: '/systemManagement/serviceAccountManagement',
    children: [
      {
        path: 'serviceAccountManagement',
        name: 'serviceAccountManagement',
        component: () =>
          import(/* webpackChunkName: "systemManagement" */ './serviceAccountManagement')
      },
      {
        path: 'serviceRoleManagement',
        name: 'serviceRoleManagement',
        component: () =>
          import(/* webpackChunkName: "systemManagement" */ './serviceRoleManagement')
      },
      {
        path: 'alertManagement',
        name: 'alertManagement',
        component: () => import(/* webpackChunkName: "systemManagement" */ './alertManagement')
      },
	  {
	    path: 'settingManagement',
	    name: 'settingManagement',
	    component: () => import(/* webpackChunkName: "systemManagement" */ './settingManagement')
	  },
      {
        path: 'operationLog',
        name: 'operationLog',
        component: () => import(/* webpackChunkName: "systemManagement" */ './operationLog')
      }
    ]
  }
]
